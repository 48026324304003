<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-23 15:00:54
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('supplier_power_childUser.65D941@Subuser_management')">
      <span class="fa fa-user fa-2x"></span>
    </page-top>
    <el-card>
      <section style="overflow:hidden;">
        <KtButton
          :label="$t('public.DC9752@btn_add')"
          :perm="5840"
          class="table-btn btn-gray"
          style="float:right;"
          @click="add"
        ></KtButton>
      </section>
      <el-table
        :data="tableData"
        :row-class-name="$tableRowClassName"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column
          prop="uid"
          align="center"
          :label="$t('backend_shopUser_supplierUser.CB120A@user_id')"
        ></el-table-column>
        <el-table-column
          min-width="100"
          prop="group_name"
          align="center"
          :label="$t('backend_authrity_grouping.5EDDBF@grouping_name')"
        ></el-table-column>
        <el-table-column
          min-width="100"
          prop="user_name"
          align="center"
          :label="$t('placeholder.9A92DD@username')"
        ></el-table-column>
        <el-table-column
          min-width="100"
          prop="nick_name"
          align="center"
          :label="$t('backend_authrity_user.8319AA@nickname')"
        ></el-table-column>
        <el-table-column
          prop="avatar"
          align="center"
          :label="$t('supplier_power_childUser.825849@head_portrait')"
        >
          <template slot-scope="scope">
            <div style="width:50px;height:50px;margin:0 auto;border-radius:50%;overflow:hidden;">
              <img class="img-auto" :src="scope.row.avatar" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_superuser"
          align="center"
          :label="$t('backend_authrity_user.B5AC7E@isSuper')"
          width="80"
        >
          <template slot-scope="scope">
            <span>
              {{
              scope.row.is_superuser == 1
              ? $t('aboutus.980E0D@yes')
              : $t('aboutus.A03514@no')
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="last_login_ip"
          align="center"
          :label="$t('supplier_power_childUser.D9FF40@Last_login_IP')"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="last_login_time"
          align="center"
          :label="$t('supplier_power_childUser.AADC1A@LastLogin_Time')"
        ></el-table-column>
        <el-table-column
          prop="status"
          align="center"
          :label="$t('supplier_power_childUser.F18109@state')"
        ></el-table-column>
        <el-table-column
          :label="$t('public.03EA09@operation')"
          align="center"
          width="360"
          fixed="right"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.allow_modify">
              <KtButton
                v-if="
                  scope.row.status == 'LOCKED' || scope.row.status == '封鎖'
                "
                :label="$t('public.564C21@unlock')"
                @click="locked(scope.row, 0)"
                type="primary"
                :perm="3715"
              ></KtButton>
              <KtButton
                v-if="
                  scope.row.status == 'NORMAL' || scope.row.status == '正常'
                "
                :label="$t('public.DBC110@locked')"
                @click="locked(scope.row, 1)"
                type="primary"
                :perm="3715"
              ></KtButton>
              <KtButton
                v-if="scope.row.allow_modify"
                :label="$t('public.B6D57F@btn_modify')"
                @click="modify(scope.row)"
                type="primary"
                :perm="9598"
              ></KtButton>
              <KtButton
                v-if="scope.row.status != 'DELETED'"
                :label="$t('public.1FBB5E@btn_delete')"
                @click="deleteUser(scope.row)"
                type="danger"
                :perm="4612"
              ></KtButton>
              <KtButton
                v-if="scope.row.status != 'DELETED'"
                :label="$t('public.72A46B@reset_pass')"
                @click="reset(scope.row)"
                type="info"
                :perm="3574"
              ></KtButton>
            </div>
            <div v-else>
              <span>{{ $t('public.25F6B0@tips') }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <section class="flex-end mt20">
        <el-pagination
          @size-change="handleSizeChange"
          :page-sizes="[5, 10, 15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>

    <el-dialog
      :title="
        addModify == 0
          ? $t('public.DC9752@btn_add')
          : $t('public.B6D57F@btn_modify')
      "
      width="60%"
      :visible.sync="dialogVisible"
    >
      <el-row>
        <el-col :span="20" :offset="2">
          <el-form :model="dialogForm" :rules="rules" ref="form" label-width="120px">
            <el-form-item :label="$t('supplier_power_grouping.C9B87D@grouping_id')" prop="group_id">
              <el-select v-model="dialogForm.group_id">
                <el-option
                  v-for="item in options"
                  :key="item.group_id"
                  :value="item.group_id"
                  :label="item.group_name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('placeholder.9A92DD@username')" prop="user_name">
              <el-input v-model="dialogForm.user_name" :disabled="Boolean(addModify)"></el-input>
            </el-form-item>
            <el-form-item :label="$t('supplier_power_childUser.DE359D@nickname')" prop="nick_name">
              <el-input v-model="dialogForm.nick_name"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('backend_authrity_user.948EEB@password')"
              prop="password"
              v-if="!addModify"
            >
              <el-input v-model="dialogForm.password" type="password"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">
          {{
          $t('public.836FD4@cancel')
          }}
        </el-button>
        <el-button size="small" type="primary" @click="comfirmDialog('form')">
          {{
          $t('public.3B45A2@confirm')
          }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dialogForm: {
        group_id: "",
        user_name: "",
        nick_name: "",
        password: ""
      },
      rules: {
        group_id: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: 'change' },
        user_name: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: 'blur' },
        password: { required: true, message: this.$t('aboutus.B003A6@This_is_required'), trigger: 'blur' },
      },
      options: [],
      addModify: 0,
      dialogVisible: false,
      row: {},
      loading: false
    };
  },

  components: {
    pageTop,
    KtButton
  },
  filters: {
    isSuper (data) {
      return data ? $t('aboutus.980E0D@yes') : $t('aboutus.A03514@no');
    },
    allowModify (data) {
      return data ? $t('aboutus.980E0D@yes') : $t('aboutus.A03514@no');
    }
  },
  created () {
    this.getTableData();
    this.changeSelect();
  },
  methods: {
    // 获取表格数据
    getTableData () {
      this.loading = true;
      this.$http
        .getUserList({
          size: this.pageSize,
          page: this.currentPage
        })
        .then(res => {
          this.loading = false;
          console.log(res);
          this.tableData = res.data.list;
          this.total = res.data.sum;
        });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange (val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 锁定解锁
    locked (row, type) {
      this.$http
        .updateUserStat({
          uid: row.uid,
          type: type
        })
        .then(res => {
          this.getTableData();
        });
    },
    // 删除
    deleteUser (row) {
      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        this.$http
          .delUser({
            uid: row.uid
          })
          .then(res => {
            if (this.tableData.length == 1 && this.currentPage > 1) {
              this.currentPage -= 1;
            }
            this.getTableData();
          });
      });
    },
    reset (row) {
      this.$http
        .resetUserPwd({
          uid: row.uid
        })
        .then(res => {
          this.$message({
            message: res.msg
          });
        });
    },
    // 获取选择器
    changeSelect () {
      this.$http.groupSelector().then(res => {
        this.options = res.data;
      });
    },
    // 打开新增弹框
    add () {
      this.addModify = 0;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      })

    },
    // 修改时获取信息
    modify (row) {
      this.addModify = 1;
      this.row = row;
      this.$http
        .getUserInfo({
          uid: row.uid
        })
        .then(res => {
          this.dialogVisible = true;
          this.$nextTick(() => {
            this.dialogForm = res.data;
          });
        });
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      })

    },

    comfirmDialog (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addModify) {
            let params = {
              uid: this.dialogForm.uid,
              nick_name: this.dialogForm.nick_name,
              group_id: this.dialogForm.group_id
            };
            this.$http.updateUser(params).then(res => {
              this.dialogVisible = false;
              this.getTableData();
            });
          } else {
            let params = Object.assign({}, this.dialogForm);
            this.$http.addSubUser(params).then(res => {
              this.dialogVisible = false;
              this.getTableData();
            });
          }
        } else {
          return false;
        }
      });

    }
  }
};
</script>
